<template>
  <div>我的收藏</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  setup () {
    const state = reactive({})
    return {
      ...toRefs(state)
    }
  }
}
</script>